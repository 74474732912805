import { appNames } from '@dvag/dfs-constant-config/app-names';
import { handleNavigateToPath } from 'service/handleNavigateToPath';
import { route } from 'config/route';

export const applySearchForName = () => (fullName: string) => {
  const nameTrimmed = (fullName || '').replace(/\s+/g, ' ').trim();
  if (!fullName) {
    handleNavigateToPath(route.householdDashboard, '', appNames.advisor);
  } else if (!nameTrimmed) return false;
  else {
    handleNavigateToPath(`${route.householdDashboard}/suche/${nameTrimmed}`, '', appNames.advisor);
  }

  return true;
};
