import { useGetFavorites, usePageAction, useGetConsent } from '@dvag/dfs-ui-blocks/hooks';
import { useMemo } from 'react';
import { getContext, getToken } from '@dvag/dfs-orchestrator-client';
import { navigate, showMessage } from '@dvag/dfs-orchestrator-client/messengers';
import { appNames } from '@dvag/dfs-constant-config';
import featureFlag from '../service/featureFlag';
import { permissionMap } from '../utils/permission/permissionMap';

export const useGetFavoritesWithAction = (householdId?: string) => {
  const pagesQuery = useGetFavorites(
    getToken,
    featureFlag.gb?.isOn(permissionMap.canAccessNSimulation),
  );
  const { checkHasConsent, checkHasAllAdultsConsent, checkHasAllPersonsBirthday } = useGetConsent(
    householdId,
    getToken,
  );
  const favorites = pagesQuery.data;

  const errorAlert = (message: string, title: string | undefined) =>
    showMessage(title!, message, 'error', 'modal');

  const newCatalogDashboard = featureFlag.gb?.isOn('new-catalog-dashboard');

  const { pageAction } = usePageAction({
    navigate,
    currentLocation: `/${appNames.advisor}${window.location.pathname}`,
    hasConsent: checkHasConsent,
    hasAllAdultsConsent: checkHasAllAdultsConsent,
    hasAllPersonsBirthDate: checkHasAllPersonsBirthday,
    errorToast: errorAlert,
    householdId,
    newCatalogDashboard,
    permission: window?.orchestratorContext?.user?.permissions,
    getToken,
    getContext,
  });

  const favoritesWithAction = useMemo(
    () =>
      favorites.map((favorite) => ({
        ...favorite,
        onClick: () => pageAction(favorite.code),
      })),
    [favorites, pageAction],
  );

  return { data: favoritesWithAction, pageAction };
};
